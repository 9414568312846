import React, { useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { trySetLinkSource } from 'app_utils/tracking';
import { SmartLink } from 'controls/smart_link';
import { SizedImage } from 'controls/sized_image';
import { connect } from 'react-redux';
import { closeGlobalSearchUI } from './action_creators';

import BadgePlaceholderImage from 'images/badge-placeholder.png';
import './results_item.sass';

/**
 * Displays a single search result.
 *
 * @property {String} name - the name of the object this result represents
 * @property {String} id - the id of the object this result represents
 * @property {String} url - the URL the user should be directed to upon clicking this result
 * @property {String} type - the type of object this result represents; one of
 *   {BadgeTemplate, Organization, Skill}; other properties vary depending on this value
 * @property {boolean} [focus=false] - whether to focus the item (takes effect when this property
 *   first changes from false to true)
 * @property {String} searchTerm - search typed by user
 * @property {Function} componentTracking - Track search click
 */
const ResultsItem = memo(({
  name,
  id,
  url,
  type,
  focus = false,
  searchTerm,
  componentTracking,
  closeGlobalSearchUI,
  image,
  photo
}) => {
  const linkRef = useRef();

  useEffect(() => {
    if (focus) {
      linkRef.current.focus();
    }
  }, [focus]);

  /**
   * Handler for clicks on the link produced by this component.  Sets the linkSource session
   * attribute so that views from the destination page can be tracked accordingly.
   *
   * @param {Event} _evt - React event that triggered this callback; isn't needed
   */
  const handleClick = (_evt) => {
    const trackingParams = {
      type: 'search.click',
      object_type: 'User',
      snapshot_json: {
        result_type: type,
        result_name: name,
        result_id: id,
        search_term: searchTerm
      }
    };
    window.google_tag('event', 'global_search_results_click', {
      result_type: type,
      result_name: name,
      result_id: id,
      search_term: searchTerm
    });
    componentTracking(trackingParams);

    closeGlobalSearchUI();
    trySetLinkSource('search-bar');
  };

  /**
   * Returns HTML for displaying the "icon" associated with a search result, according to the type
   * of result (e.g. BadgeTemplate and Organization results show their respective images, while
   * Skill results show the number of badge templates with that skill)
   * @returns {*}
   */
  const renderIcon = () => {
    const className = 'c-global-search-results-item__icon';
    let icon;
    switch (type) {
      case 'BadgeTemplate':
        icon = (
          <SizedImage
            className={className}
            src={image ? image.url : BadgePlaceholderImage}
            alt={name}
            width={30}
          />
        );
        break;
      case 'Organization':
        icon = (
          <SizedImage
            className={className}
            src={photo.url}
            alt={name}
            width={30}
          />
        );
        break;
    }
    return icon;
  };

  return (
    <SmartLink
      className="c-global-search-results-item"
      action={url}
      ref={linkRef}
      onClick={handleClick}
    >
      {renderIcon()}
      <span className="c-global-search-results-item__label">{name}</span>
    </SmartLink>
  );
});

ResultsItem.propTypes = {
  closeGlobalSearchUI: PropTypes.func.isRequired,
  focus: PropTypes.bool,
  type: PropTypes.oneOf([
    'BadgeTemplate',
    'Occupation',
    'Organization',
    'Skill'
  ]).isRequired,
  image: PropTypes.shape({ url: PropTypes.string }),
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  photo: PropTypes.shape({ url: PropTypes.string }),
  url: PropTypes.string.isRequired,
  searchTerm: PropTypes.string,
  componentTracking: PropTypes.func.isRequired
};

const Connected = connect(null, { closeGlobalSearchUI })(ResultsItem);
Connected.displayName = 'ResultsItem';

export { Connected as ResultsItem };
export const testing = { ResultsItem };
