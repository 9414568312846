import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Routes } from 'utils';
import { isFeatureEnabled } from 'app_utils';
import { SmartLink } from 'controls/smart_link';
import Icon from 'controls/icon';
import {
  getProfileAction,
  hasCredentialSolutionsAccess,
  hasWorkforceAccess,
  isActiveOrgMember
} from 'profile';
import { faUserAstronaut } from '@fortawesome/pro-light-svg-icons/faUserAstronaut';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faBinoculars } from '@fortawesome/pro-light-svg-icons/faBinoculars';
import { faBriefcase } from '@fortawesome/pro-light-svg-icons/faBriefcase';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import DashboardIcon from 'svg/dashboard.svg';
import BadgesIcon from 'svg/badges.svg';
import { FormattedMessage, injectIntl } from "react-intl";
import { talentMatcherActions } from 'pages/credly_recruit/action_creators';

import './header_menu_nav.sass';

// NOTE: Any change made here must also be made to _main_menu.html.haml

const getProfileLinkParams = (userVanitySlug) => ({
  action: Routes.userVanityUrl(userVanitySlug),
  className: 'profile',
  icon: faUserAstronaut,
  linkText: <FormattedMessage
    id="header_menu_nav.view_profile"
    defaultMessage="View Profile"
  />
});
const sendTranscriptLinkParams = {
  action: Routes.transcriptUrl.new(),
  className: 'transcript',
  icon: faEnvelope,
  linkText: <FormattedMessage
    id="header_menu_nav.send_transcript"
    defaultMessage="Send Transcript"
  />
};
const settingsLinkParams = {
  action: Routes.editAccountUrl(),
  className: 'settings',
  icon: faCog,
  linkText: <FormattedMessage
    id="header_menu_nav.settings"
    defaultMessage="Settings"
  />
};
const dashLinkParams = {
  action: Routes.earnerDashboardUrl(),
  className: 'dashboard',
  icon: DashboardIcon,
  linkText: <FormattedMessage
    id="header_menu_nav.dashboard"
    defaultMessage="Dashboard"
  />
};
const badgesLinkParams = {
  action: Routes.earnerBadgesUrl(),
  className: 'badges',
  icon: BadgesIcon,
  linkText: <FormattedMessage
    id="header_menu_nav.badges"
    defaultMessage="Badges"
  />
};
const orgManLinkParams = {
  action: Routes.profileUrl.organization(),
  className: 'org-management',
  icon: faBuilding,
  linkText: <FormattedMessage
    id="header_menu_nav.organization"
    defaultMessage="Organization"
  />
};

const jobBoardLinkParams = {
  action: Routes.jobBoardUrl(),
  className: 'job-board',
  icon: faBinoculars,
  linkText: <FormattedMessage
    id="header_menu_nav.job_board"
    defaultMessage="Search Jobs"
  />
};

const recruiterLinkParams = {
  action: Routes.recruitUrl(),
  className: 'recruit',
  icon: faBriefcase,
  linkText: <FormattedMessage
    id="header_menu_nav.recruit"
    defaultMessage="Recruit"
  />
};

const talentMatcherLinkParams = {
  action: Routes.talentMatchUrl(),
  className: 'talent-match',
  icon: faBriefcase,
  linkText: <FormattedMessage
    id="header_menu_nav.talent_match"
    defaultMessage="Talent Match"
  />
};

const workforceLinkParams = {
  action: Routes.workforceUrl.root(),
  className: 'workforce',
  icon: faUsers,
  linkText: (
    <FormattedMessage
      id="header_menu_nav.workforce"
      defaultMessage="Workforce"
    />
  )
};

const signOutLinkParams = {
  action: Routes.signOutUrl(),
  className: 'sign-out',
  icon: faSignOut,
  linkText: <FormattedMessage
    id="header_menu_nav.sign_out"
    defaultMessage="Sign Out"
  />
};

class HeaderMenuNav extends Component {
  /**
   * Renders a link for the menu
   * @returns {React.element}
   */
  renderNavLink = (paramsHash, ariaLabel = '', child = undefined) => {
    return (
      <SmartLink
        className={`main-menu-link main-menu-link--${paramsHash.className}`}
        onClick={this.props.handleClick}
        action={paramsHash.action}
        aria-label={ariaLabel}
      >
        <Icon image={paramsHash.icon} className="main-menu-icon" />
        <span>{paramsHash.linkText}</span>
        {child || undefined}
      </SmartLink>
    );
  };

  handleBlur = (ev) => {
    if (!ev.currentTarget.contains(ev.relatedTarget)) {
      this.props.handleClick();
    }
  }

  handleKeyDown = (ev) => {
    if (ev.key === 'Escape') {
      this.props.handleClick();
      ev.stopPropagation();
    }
  }

  /**
   * Renders the component
   * @returns {React.element}
   */
  render() {
    const user = this.props.currentUser;
    const talentMatcher = this.props.talentMatcher || {};
    const jobBoardEligible = (user?.talent_match_opt_in && !user?.workforce_earner);
    const badgeCount = parseInt(user.pending_badge_count || 0);
    const EARNER_JOB_BOARD_ENABLED = 'earner-job-board';

    return (
      <Fragment>
        <div
          className="main-menu-container"
          id="header-nav-menu"
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
        >
          <div className="main-menu main-menu--react-version">
            <div className="main-menu-body">
              <section className="main-menu-section">
                {this.renderNavLink(dashLinkParams, "Dashboard")}
                {this.renderNavLink(badgesLinkParams,
                  this.props.intl.formatMessage({
                    id: 'header_menu_nav.pending_badges',
                    defaultMessage: 'Badges {badgeCount} pending badges'
                  }, { badgeCount }),
                  badgeCount &&
                    <i className="notifications-counter notifications-counter--main-menu-icon">
                      {badgeCount}
                    </i>
                )}
                {this.renderNavLink(getProfileLinkParams(user.vanity_slug))}
                {this.renderNavLink(sendTranscriptLinkParams)}
              </section>
              {(isFeatureEnabled(EARNER_JOB_BOARD_ENABLED) && jobBoardEligible) &&
                <section className="main-menu-section">
                  {this.renderNavLink(jobBoardLinkParams)}
                </section>
              }
              {(isActiveOrgMember(user) || user.recruiter || talentMatcher.active_talent_matcher) &&
                <section className="main-menu-section">
                  {hasCredentialSolutionsAccess(user) && this.renderNavLink(orgManLinkParams)}
                  {user.recruiter && this.renderNavLink(recruiterLinkParams)}
                  {talentMatcher.active_talent_matcher &&
                    this.renderNavLink(talentMatcherLinkParams)
                  }
                  {hasWorkforceAccess(user) && this.renderNavLink(workforceLinkParams)}
                </section>
              }
              <section className="main-menu-section">
                {this.renderNavLink(settingsLinkParams)}
                {this.renderNavLink(signOutLinkParams)}
              </section>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

HeaderMenuNav.propTypes = {
  handleClick: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    pending_badge_count: PropTypes.number,
    recruiter: PropTypes.bool,
    vanity_slug: PropTypes.string
  }),
  talentMatcher: PropTypes.shape({
    user_id: PropTypes.string,
    active_talent_matcher: PropTypes.bool,
    can_post_job: PropTypes.bool,
    can_smart_match: PropTypes.bool
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  })
};

const mapStateToProps = (state) => {
  const tmAction = talentMatcherActions.get('show', 'self');
  return {
    currentUser: getProfileAction.getResources(state),
    talentMatcher: tmAction.getResources(state)
  };
};

const ConnectedHeaderMenuNav = injectIntl(
  connect(mapStateToProps)(HeaderMenuNav)
);

export { ConnectedHeaderMenuNav as HeaderMenuNav };
export const testing = { HeaderMenuNav };
